.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.logout-btn {
    position: absolute;
    right: 0px;
}

.main-container{
  max-width: unset !important;
  width: 90% !important;
}

.logout-container{
  position: relative;
  width:100%;
  height:45px;
  margin-bottom: 10px;
}

.accordion-container{
    margin-bottom: 30px;
}

.table-container{
    margin-top: 30px;
    overflow-x: auto;
}

.accordion .card-body{
    padding: 0;
}

.accordion .card-body table{
    margin:0;
    border-width: auto 0 0 0;
}